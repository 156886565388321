import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UserService } from "../../services/user.service";
import { ToasterService } from "../../services/toaster.service";

@Component({
	selector: "app-profile",
	templateUrl: "./profile.component.html",
	styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
	file: any;
	firstName: any;
	lastName: any;
	email: any;
	fileDocumentId: any;
	defaultImageUrl: string =
		"https://papaya-health.s3.ca-central-1.amazonaws.com/papaya/USER/images/1715583811878_user.png";
	imageUrl: string = this.defaultImageUrl;
	userId: any;
	imageId: any;

	constructor(
		public dialogRef: MatDialogRef<ProfileComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private userService: UserService,
		private toaster: ToasterService,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.userId = localStorage.getItem("userId");
		this.getDetails();
	}

	onFileSelected(event: any) {
		this.file = event.target.files[0];
		if (this.file) {
			this.onFileUpload();
		}
	}

	close() {
		this.dialogRef.close();
	}

	onFileUpload() {
		const formData = new FormData();
		formData.append("image", this.file);
		this.addImage(formData);
	}

	changeSource(event: any) {
		event.target.src = this.defaultImageUrl;
	}

	addImage(formData: any) {
		this.userService.userImage(formData).subscribe(
			(res: any) => {
				this.fileDocumentId = res.body.data.documentId;
				this.imageUrl = res.body.data.imageUrl;
				this.toaster.success("File uploaded successfully.");
				this.cdr.detectChanges();
			},
			(err) => {
				this.toaster.error("Error uploading file");
			}
		);
	}

	getDetails() {
		this.userService.getPractitionerDetail(this.userId).subscribe(
			(res: any) => {
				this.firstName = res.data.firstName;
				this.lastName = res.data.lastName;
				this.email = res.data.email;
				this.imageUrl = res.data.imageUrl || this.defaultImageUrl;

				if (
					!res.data.imageUrl ||
					res.data.imageUrl === "N/A" ||
					res.data.imageUrl === null
				) {
					this.imageUrl = this.defaultImageUrl;
				} else {
					this.imageUrl = res.data.imageUrl;
				}
			},
			(error: any) => {
				this.toaster.error("Error fetching user details.");
			}
		);
	}

	submit() {
		if (!this.firstName) {
			this.toaster.error("First Name is required.");
			return;
		}
		if (!this.email || !this.validateEmail(this.email)) {
			this.toaster.error("A valid email is required.");
			return;
		}
		const userData = {
			firstName: this.firstName,
			lastName: this.lastName,
			email: this.email,
			imageId: this.fileDocumentId,
		};
		this.userService.editPractitioner(this.userId, userData).subscribe(
			(res: any) => {
				if (res.success === true) {
					this.toaster.success("Profile updated successfully");
					localStorage.setItem("imageUrl", this.imageUrl);
					localStorage.setItem(
						"userName",
						this.firstName + " " + this.lastName
					);
					this.cdr.detectChanges();
					this.dialogRef.close(res);
				}
			},
			(error: any) => {
				if (error.httpStatus === 400 || (error.error && error.error.message)) {
					this.toaster.error(error.error.message);
				} else this.toaster.error("Error updating profile.");
			}
		);
	}

	validateEmail(email: string): boolean {
		const emailRegex =
			/^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(email);
	}
}
