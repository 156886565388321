<mat-dialog-content>
    <div class="icon">
        <img src="../../../../assets/images/close.png" (click)="close()" class="close-icon" />
    </div>
    <div>
        <div class="example-full-width my-3">
            <div style="display: flex; justify-content: center;">
                <label>Profile Image</label>
            </div>
            <div class="image-container">
                <input #inputRef class="d-none form-control" type="file" name="imageUrl"
                    (change)="onFileSelected($event)" multiple="false">
                <img class="imageSize" (error)="changeSource($event)" title="upload image" (click)="inputRef.click()"
                    [src]="imageUrl" alt="userImage">
            </div>
        </div>
        <div class="example-full-width my-3">
            <label>First Name</label><span class="text-danger">*</span>
            <input class="form-control" type="text" [(ngModel)]="firstName" #firstNameField="ngModel" name="firstName"
                maxlength="30" required>

            <div *ngIf="firstNameField.invalid && firstNameField.touched" class="text-danger">
                First Name is required.
            </div>

        </div>
        <div class="example-full-width my-3">
            <label>Last Name</label>
            <input class="form-control" type="text" [(ngModel)]="lastName" name="lastName" maxlength="30">
        </div>
        <div class="example-full-width my-3">
            <label>Email</label><span class="text-danger">*</span>
            <input class="form-control" type="email" [(ngModel)]="email" #emailField="ngModel" name="email" required
                email>

            <div *ngIf="emailField.invalid && emailField.touched" class="text-danger">
                <div *ngIf="emailField.errors?.['required']">Email is required.</div>
                <div *ngIf="emailField.errors?.['email']">Invalid email format.</div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button type="submit" color="primary" (click)="submit()" class="btnText">Update</button>
    <button mat-raised-button (click)="close()">Close</button>
</mat-dialog-actions>