<div class="caontainer-size">
    <div class="row">
        <div class="d-flex justify-content-center align-item-center col-md-6 col-sm-12">
            <img src="../../../assets/Papaya.svg" alt="Image" class="img-fluid">
        </div>
        <div class="col-md-6 contents">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="mb-4">
                        <h3 class="textDecor">Admin Reset Password</h3>
                        <p class="mb-4 textDecor">Reset Your Password</p>
                    </div>
                    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                        <div class="field-holder">
                            <input [type]="isShow ? 'text' : 'password'" formControlName="newPassword" id="newPassword" required>
                            <label for="newPassword"> Enter new password</label>
                            <mat-icon class="eye-icon" *ngIf="isShow" (click)="togglePassword()">visibility</mat-icon>
                            <mat-icon class="eye-icon" *ngIf="!isShow" (click)="togglePassword()">visibility_off</mat-icon>
                        </div>
                        <div class="text-color" *ngIf="resetPasswordForm.get('newPassword').invalid && (resetPasswordForm.get('newPassword').dirty || resetPasswordForm.get('newPassword').touched)">
                            Please enter new password.
                          </div>
                          <div class="field-holder">
                            <input [type]="isShowconfirm ? 'text' : 'password'" formControlName="confirmPassword" id="confirmPassword" required>
                            <label for="confirmPassword"> Confirm password</label>
                            <mat-icon class="eye-icon" *ngIf="isShowconfirm" (click)="togglePasswordConfirm()">visibility</mat-icon>
                            <mat-icon class="eye-icon" *ngIf="!isShowconfirm" (click)="togglePasswordConfirm()">visibility_off</mat-icon>
                        </div>
                        <div class="text-color" *ngIf="resetPasswordForm.hasError('passwordMismatch') && (resetPasswordForm.get('confirmPassword').dirty || resetPasswordForm.get('confirmPassword').touched)">
                            Passwords do not match.
                        </div>
                        <div class="form-group mt-5">
                            <button [disabled]="resetPasswordForm.invalid" type="submit" class="btn btn-block btn-primary loginbtn w-100">Submit</button>
                        </div>
                    </form>
                </div>
                 
            </div>
        </div>
    </div>
</div>