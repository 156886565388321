import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../../services/user.service";
import { ToasterService } from "../../services/toaster.service";
import { Router } from "@angular/router";

@Component({
	selector: "app-user-reset-password",
	templateUrl: "./user-reset-password.component.html",
	styleUrl: "./user-reset-password.component.css",
})
export class UserResetPasswordComponent implements OnInit {
	changePasswordForm: FormGroup | any;
	userEmail;
	isShowOldPassword = false;
	isShowNewPassword = false;
	isShowConfirmPassword = false;

	constructor(
		public dialogRef: MatDialogRef<UserResetPasswordComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private formBuilder: FormBuilder,
		private userService: UserService,
		private toaster: ToasterService,
		readonly router: Router
	) {
		this.userEmail = localStorage.getItem("userEmail");
	}

	ngOnInit() {
		this.resetPass();
	}

	resetPass() {
		this.changePasswordForm = this.formBuilder.group(
			{
				email: [this.userEmail, Validators.required],
				oldPassword: ["", Validators.required],
				newPassword: ["", Validators.required],
				confirmPassword: ["", Validators.required],
			},
			{
				validator: this.passwordsShouldNotMatch("oldPassword", "newPassword"),
			}
		);
	}

	passwordsShouldNotMatch(oldPasswordKey: string, newPasswordKey: string) {
		return (formGroup: FormGroup) => {
			const oldPasswordControl = formGroup.controls[oldPasswordKey];
			const newPasswordControl = formGroup.controls[newPasswordKey];

			if (
				newPasswordControl.errors &&
				!newPasswordControl.errors["mustNotMatch"]
			) {
				return;
			}

			if (oldPasswordControl.value === newPasswordControl.value) {
				newPasswordControl.setErrors({ mustNotMatch: true });
			} else {
				newPasswordControl.setErrors(null);
			}
		};
	}

	onSubmit() {
		if (this.changePasswordForm?.valid) {
			this.userService
				.resetPassword(this.changePasswordForm.value)
				.subscribe((res: any) => {
					if (res.httpStatus === 400) {
						this.toaster.error(res.message);
					} else {
						this.toaster.success(res.message);
						this.changePasswordForm.reset();
						this.dialogRef.close();
						localStorage.clear();
						this.router.navigate(["/login"]);
					}
				});

			// Reset form after submission if needed
		}
	}

	toggleOldPassword() {
		this.isShowOldPassword = !this.isShowOldPassword;
	}
	toggleNewPassword() {
		this.isShowNewPassword = !this.isShowNewPassword;
	}

	toggleConfirmPassword() {
		this.isShowConfirmPassword = !this.isShowConfirmPassword;
	}
	close(): void {
		this.dialogRef.close();
	}
}
