import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { ToasterService } from "./toaster.service";

export const authGuard: CanActivateFn = (route, state) => {
	const _router = inject(Router);
	const toaster = inject(ToasterService);
	let token = localStorage.getItem("token") || route.queryParams["token"];
	if (!token) {
		toaster.error("Token Expire");
		_router.navigate(["/login"]);
		return false;
	}
	return true;
};
