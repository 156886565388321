<body>
    <div class="container">
        <div class="text-center">

            <img alt="Papaya Health" class="left fixedwidth"
                src="https://img1.wsimg.com/isteam/ip/7c5503d7-591f-48cd-8896-ed3602187251/Logo.png/:/rs=w:481,h:192,cg=true,m/cr=w:481,h:192/qt=q:95"
                style="margin-top: 10px; margin-bottom: 10px;text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 30%; text-align:center" />

        </div>
        <div class="header">
            <h1>Email Verification</h1>
        </div>
        <div class="icon">
            <mat-icon *ngIf="check"><span class="greenicon icon check material-symbols-outlined">
                    check_circle
                </span></mat-icon>
            <mat-icon *ngIf="cross">

                <span class="redIcon check material-symbols-outlined">
                    cancel
                </span>
            </mat-icon>
        </div>
        <div class="content">
            <h5 *ngIf="cross">Sorry, your E-mail Id could not be verified. </h5>
            <p class="pt-2" *ngIf="cross">Either the verification link has expired or the Email ID has already been
                verified. In
                case you are still facing issues, please write to us at <strong>admin&#64;papayahealth.ca</strong>
            </p>

            <p *ngIf="check">{{message}}</p>
        </div>
    </div>
</body>