import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { LoaderService } from "./loader.service";
import { catchError, finalize, tap } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";

@Injectable()
export class interceptorInterceptor implements HttpInterceptor {
	baseUrl;
	paramsToken: any;
	constructor(
		readonly router: Router,
		readonly spiner: LoaderService,
		private route: ActivatedRoute
	) {
		this.baseUrl = environment.SERVER_URL;
	}
	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const token = localStorage.getItem("token");
		this.route.queryParams.subscribe((params) => {
			this.paramsToken = params["token"];
		});
		req = req.clone({ url: req.url });
		if (req.url?.includes("cronofy/userProfile")) {
			this.router.navigate(["/calendar-integration"]);
		} else if (req.url?.includes("verify-email")) {
			this.router.navigate(["/verify-email"]);
		} else if (this.paramsToken) {
			this.router.navigate(["/verify-email"]);
		} else if (token) {
			req = req.clone({
				headers: req.headers.set("Authorization", `Bearer ${token}`),
			});
		} else {
			localStorage.clear();
			this.router.navigate(["/login"]);
		}
		this.spiner.showLoader();

		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {
				console.log("error ==>> ", error);
				if (
					error.status === 400 &&
					error.url?.includes("cronofy/userProfile")
				) {
					this.router.navigate(["/calendar-integration"]);
				} else if (!this.paramsToken) {
					if (error.status === 400 && error.url?.includes("verifyUser")) {
						this.router.navigate(["/verify-email"]);
					}
					if (
						error.status === 0 ||
						error.status === 401 ||
						error.status === 403
					) {
						localStorage.clear();
						this.router.navigate(["/login"]);
					}
				}
				return throwError(error);
			}),
			finalize(() => {
				this.spiner.hideLoader();
			})
		);
	}
}
