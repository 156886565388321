// import {
// 	AfterViewInit,
// 	Component,
// 	OnChanges,
// 	OnInit,
// 	ViewChild,
// } from "@angular/core";
// import { MatSidenav } from "@angular/material/sidenav";
// import { BreakpointObserver } from "@angular/cdk/layout";
// import { Route, Router } from "@angular/router";
// import { MatDialog } from "@angular/material/dialog";
// import { UserResetPasswordComponent } from "../shared/dialogs/user-reset-password/user-reset-password.component";
// import { ProfileComponent } from "../shared/dialogs/profile/profile.component";
// import { UserDetailsService } from "../shared/services/user-details.service";

// @Component({
// 	selector: "app-layout",
// 	templateUrl: "./layout.component.html",
// 	styleUrl: "./layout.component.scss",
// })
// export class LayoutComponent implements AfterViewInit, OnInit, OnChanges {
// 	[x: string]: any;
// 	userName;
// 	userRole;
// 	permissions: any;
// 	oldPassword: any;
// 	newPassword: any;
// 	confirmPassword: any;
// 	imageUrl: any;

// 	@ViewChild("sidenav") sidenavRef!: MatSidenav;
// 	hasChilds: boolean = false;
// 	currentSubMenu: string = "";
// 	constructor(
// 		private observer: BreakpointObserver,
// 		private router: Router,
// 		public dialog: MatDialog,
// 		private userDetailsService: UserDetailsService
// 	) {
// 		this.userName = localStorage.getItem("userName");
// 		this.userRole = localStorage.getItem("userRole");
// 		this.imageUrl = localStorage.getItem("imageUrl");
// 		this.permissions = JSON.parse(localStorage.getItem("permissions") || "[]");
// 	}

// 	ngOnInit(): void {
// 		this.userDetailsService.currentProfile.subscribe((profile) => {
// 			this.userName = profile.name;
// 			this.imageUrl = profile.imageUrl;
// 		});
// 		this.setOpen();
// 	}

// 	ngOnChanges(): void {}

// 	toggleSubMenu(subMenu: string) {
// 		if (this.currentSubMenu === subMenu) {
// 			this.currentSubMenu = "";
// 		} else {
// 			this.currentSubMenu = subMenu;
// 		}
// 	}

// 	openResetPasswordDialog(): void {
// 		const dialogRef = this.dialog.open(UserResetPasswordComponent, {
// 			data: {
// 				oldPassword: this.oldPassword,
// 				newPassword: this.newPassword,
// 				confirmPassword: this.confirmPassword,
// 			},
// 			width: "500px",
// 			height: "400px",
// 		});
// 		dialogRef.afterClosed().subscribe((result) => {
// 			console.log("The dialog was closed");
// 			console.log(result);
// 		});
// 	}

// 	navigateToProfile(): void {
// 		const dialogRef = this.dialog.open(ProfileComponent, {
// 			data: {},
// 			width: "500px",
// 			height: "400px",
// 		});
// 		dialogRef.afterClosed().subscribe((result) => {
// 			console.log("The dialog was closed");
// 		});
// 	}

// 	logout() {
// 		localStorage.removeItem("token");
// 		localStorage.clear();
// 		this.router.navigate(["/login"]);
// 	}

// 	isActive(route: string): boolean {
// 		if (route === "/dashboard" && this.router.url.startsWith(route)) {
// 			return true;
// 		}
// 		return this.router.isActive(route, false);
// 	}

// 	ngAfterViewInit(): void {
// 		this.observer.observe(["(max-width:800px)"]).subscribe((res) => {
// 			if (res.matches) {
// 				this.sidenavRef.mode = "over";
// 				this.sidenavRef.close();
// 			} else {
// 				this.sidenavRef.mode = "side";
// 				this.sidenavRef.open();
// 			}
// 		});
// 	}

// 	setOpen() {
// 		const url = this.router.url;
// 		if (url.includes("/user/")) {
// 			this.currentSubMenu = "User";
// 		}
// 		if (url.includes("/cms/")) {
// 			this.currentSubMenu = "CMS";
// 		}
// 		if (url.includes("/feedback/")) {
// 			this.currentSubMenu = "Feedback";
// 		}
// 		return this.currentSubMenu;
// 	}
// }

import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	OnInit,
	ViewChild,
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { UserResetPasswordComponent } from "../shared/dialogs/user-reset-password/user-reset-password.component";
import { ProfileComponent } from "../shared/dialogs/profile/profile.component";
@Component({
	selector: "app-layout",
	templateUrl: "./layout.component.html",
	styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements AfterViewInit, OnInit {
	userRole: string | null = null;
	// name: any;
	email: string = "";
	permissions: any;
	oldPassword: any;
	newPassword: any;
	confirmPassword: any;
	imageUrl: any =
		"https://papaya-health.s3.ca-central-1.amazonaws.com/papaya/USER/images/1715583811878_user.png";

	@ViewChild("sidenav") sidenavRef!: MatSidenav;
	hasChilds: boolean = false;
	currentSubMenu: string = "";
	userName: any;

	constructor(
		private observer: BreakpointObserver,
		private router: Router,
		public dialog: MatDialog,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.userName = localStorage.getItem("userName");
		this.imageUrl = localStorage.getItem("imageUrl");
		this.userRole = localStorage.getItem("userRole");
		this.permissions = JSON.parse(localStorage.getItem("permissions") || "[]");
		this.cdr.detectChanges();
		this.setOpen();
	}

	toggleSubMenu(subMenu: string) {
		if (this.currentSubMenu === subMenu) {
			this.currentSubMenu = "";
		} else {
			this.currentSubMenu = subMenu;
		}
	}

	openResetPasswordDialog(): void {
		const dialogRef = this.dialog.open(UserResetPasswordComponent, {
			data: {
				oldPassword: this.oldPassword,
				newPassword: this.newPassword,
				confirmPassword: this.confirmPassword,
			},
			width: "500px",
			height: "400px",
		});
		dialogRef.afterClosed().subscribe((result) => {
			console.log("The dialog was closed");
		});
	}

	navigateToProfile(): void {
		const dialogRef = this.dialog.open(ProfileComponent, {
			data: {},
			width: "500px",
			height: "400px",
		});
		dialogRef.afterClosed().subscribe((res) => {
			console.log(res.data.imageUrl);
			this.imageUrl = res.data.imageUrl || this.imageUrl;
			this.userName =
				res?.data?.firstName + " " + res?.data?.lastName || this.userName;

			console.log(res, "The dialog was closed");
		});
	}

	logout() {
		localStorage.removeItem("token");
		localStorage.clear();
		this.router.navigate(["/login"]);
	}

	isActive(route: string): boolean {
		if (route === "/dashboard" && this.router.url.startsWith(route)) {
			return true;
		}
		return this.router.isActive(route, false);
	}

	ngAfterViewInit(): void {
		this.observer.observe(["(max-width:800px)"]).subscribe((res) => {
			if (res.matches) {
				this.sidenavRef.mode = "over";
				this.sidenavRef.close();
			} else {
				this.sidenavRef.mode = "side";
				this.sidenavRef.open();
			}
			this.cdr.detectChanges();
		});
	}

	setOpen() {
		const url = this.router.url;
		if (url.includes("/user/")) {
			this.currentSubMenu = "User";
		}
		if (url.includes("/cms/")) {
			this.currentSubMenu = "CMS";
		}
		if (url.includes("/feedback/")) {
			this.currentSubMenu = "Feedback";
		}
		if (url.includes("/terms-policies/")) {
			this.currentSubMenu = "TermsPolicies";
		}
		return this.currentSubMenu;
	}
}
