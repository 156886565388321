import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { EmailVerificationService } from "../../../shared/services/verification-service.service";

@Component({
	selector: "app-verify-email",
	templateUrl: "./verify-email.component.html",
	styleUrl: "./verify-email.component.css",
})
export class VerifyEmailComponent implements OnInit {
	message: any;
	token: any;
	check = false;
	cross = false;

	constructor(
		private route: ActivatedRoute,
		private emailVerificationService: EmailVerificationService
	) {}

	ngOnInit(): void {
		this.route.queryParams.subscribe((params: any) => {
			this.token = params["token"];
			if (this.token) {
				this.emailVerificationService.verifyEmail(this.token).subscribe(
					(response: any) => {
						this.message = response.message;
						this.check = true;
					},
					(error) => {
						this.message = error.error.message;
						this.cross = true;
					}
				);
			} else {
				this.message = "No Token Provided";
			}
		});
	}
}
