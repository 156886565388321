import { NgModule } from "@angular/core";
import { AsyncPipe, CommonModule } from "@angular/common";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { MaterialModule } from "../material/material.module";
import { UserResetPasswordComponent } from "./dialogs/user-reset-password/user-reset-password.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToasterComponent } from "./components/toaster/toaster.component";
import { DeleteConfirmationComponent } from "./dialogs/delete-confirmation/delete-confirmation.component";
import { AddExpertiseComponent } from "./dialogs/add-expertise/add-expertise.component";
import { AddPlanComponent } from "./dialogs/add-plan/add-plan.component";
import { AddFaqComponent } from "./dialogs/add-faq/add-faq.component";
import { SupportReplyComponent } from "./dialogs/support-reply/support-reply.component";
import { AddLocationComponent } from "./dialogs/add-location/add-location.component";
import { ShowDetailsComponent } from "./dialogs/show-details/show-details.component";
import { DatePipe } from "@angular/common";
import { ShowUserFeedbackComponent } from "./dialogs/show-user-feedback/show-user-feedback.component";
import { ProfileComponent } from "./dialogs/profile/profile.component";
import { DetailsComponent } from "./dialogs/details/details.component";
import { AddMessageComponent } from "./dialogs/add-message/add-message.component";
import { AddTermsPoliciesComponent } from "./dialogs/add-terms-policies/add-terms-policies.component";
import { AlertMessageComponent } from "./dialogs/alert-message/alert-message.component";

@NgModule({
	declarations: [
		FooterComponent,
		HeaderComponent,
		SidebarComponent,
		SpinnerComponent,
		UserResetPasswordComponent,
		ToasterComponent,
		DeleteConfirmationComponent,
		AddExpertiseComponent,
		AddPlanComponent,
		AddFaqComponent,
		SupportReplyComponent,
		AddLocationComponent,
		ShowDetailsComponent,
		ShowUserFeedbackComponent,
		ProfileComponent,
		DetailsComponent,
		AddMessageComponent,
		AddTermsPoliciesComponent,
		AlertMessageComponent,
	],
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule,
		HttpClientModule,
		MaterialModule,
		ReactiveFormsModule,
		FormsModule,
		AsyncPipe,
	],
	exports: [
		FooterComponent,
		HeaderComponent,
		SidebarComponent,
		SpinnerComponent,
		UserResetPasswordComponent,
		ToasterComponent,
		DeleteConfirmationComponent,
	],
	providers: [DatePipe],
})
export class SharedModule {}
