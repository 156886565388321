<div class="caontainer-size">
    <div class="row">
        <div class="d-flex align-item-center justify-content-center col-md-6 col-sm-12">
            <img src="../../../../../assets/Papaya.svg" alt="Image" class="img-fluid">
        </div>
        <div class="col-md-6 contents">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="mb-4">
                        <h3 class="textDecor">Admin Reset Password</h3>
                        <p class="mb-4 textDecor">Enter Your {{ !otpBox ? 'Email Address':'OTP'}}</p>
                    </div>
                    <form [formGroup]="forgotPass" (ngSubmit)="onSubmit()">
                        <div *ngIf="!otpBox" class="field-holder">
                            <input type="text" formControlName="email" id="email" required>
                            <label for="email"> Email</label>
                        </div>
                        <div *ngIf="forgotPass.get('email').invalid && forgotPass.get('email').touched"
                            class="text-color">
                            <div *ngIf="forgotPass.get('email').errors.required">Email is required.</div>
                            <div *ngIf="forgotPass.get('email').errors.email">Invalid email format.</div>
                        </div>
                        <div class="col-md-9 mt-2">
                            <a routerLink="/login" class="forgetPassLink cursor">
                                Back to Log In page?
                            </a>
                        </div>
                        <div class="form-group mt-5">
                            <button type="submit" [disabled]="forgotPass.get('email').invalid"
                                class="btn btn-block btn-primary loginbtn w-100">Send OTP</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>