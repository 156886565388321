import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToasterService } from "../../services/toaster.service";

@Component({
	selector: "app-add-terms-policies",
	templateUrl: "./add-terms-policies.component.html",
	styleUrl: "./add-terms-policies.component.css",
})
export class AddTermsPoliciesComponent implements OnInit {
	heading: any;
	content: any;
	isEdit = false;
	type: any = "";
	contentType: string = "";
	constructor(
		public dialogRef: MatDialogRef<AddTermsPoliciesComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private toaster: ToasterService
	) {}

	ngOnInit(): void {
		console.log(this.data);
		this.isEdit = this.data.isEdit;
		this.heading = this.data?.rowData?.heading;
		this.content = this.data?.rowData?.content;
	}

	submit() {
		let payload = {
			heading: this.heading,
			content: this.content,
			contentType: this.data.contentType,
		};
		if (!payload.heading) {
			this.toaster.warning("Please enter heading");
			return;
		}
		if (!payload.content) {
			this.toaster.warning("Please enter content");
			return;
		}
		this.dialogRef.close(payload);
	}
	close() {
		this.dialogRef.close();
	}
}
