import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LayoutComponent } from "./layout/layout.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { SharedModule } from "./shared/shared.module";
import { LoginComponent } from "./authentication/pages/login/login.component";
import { ResetPasswordComponent } from "./authentication/pages/reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./authentication/pages/forgot-password/forgot-password/forgot-password.component";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { MaterialModule } from "./material/material.module";
import { OtpComponent } from "./authentication/pages/otp/otp.component";
import { interceptorInterceptor } from "./shared/services/interceptor.interceptor";
import { VerifyEmailComponent } from "./authentication/pages/verify-email/verify-email.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { CalenderIntegrationComponent } from "./authentication/pages/calender-integration/calender-integration.component";

@NgModule({
	declarations: [
		AppComponent,
		LayoutComponent,
		LoginComponent,
		ResetPasswordComponent,
		ForgotPasswordComponent,
		OtpComponent,
		VerifyEmailComponent,
		CalenderIntegrationComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		ReactiveFormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		CommonModule,
		SharedModule,
		MaterialModule,
		FormsModule,
		CKEditorModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: interceptorInterceptor,
			multi: true,
		},
		provideAnimationsAsync(),
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
