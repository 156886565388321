import { Injectable } from "@angular/core";
import { UrlService } from "../../shared/services/url.service";
import { Observable } from "rxjs";
import { ApiService } from "../../shared/services/api.service";

@Injectable({
	providedIn: "root",
})
export class LoginService {
	constructor(private apiService: ApiService, private url: UrlService) {}

	login(payload: any): Observable<any[]> {
		return this.apiService.post(this.url.LOGIN, payload);
	}

	forgotPassword(payload: any): Observable<any[]> {
		return this.apiService.put(this.url.FORGOT_PASSWORD, payload);
	}
}
