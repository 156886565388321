<!-- <body>
    <div class="container">
        <div class="logo-container">
            <img alt="Papaya Health" class="left fixedwidth"
                src="https://img1.wsimg.com/isteam/ip/7c5503d7-591f-48cd-8896-ed3602187251/Logo.png/:/rs=w:481,h:192,cg=true,m/cr=w:481,h:192/qt=q:95"
                style="margin-top: 0px; margin-bottom: 10px;text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 30%; text-align:center" />
        </div>

        <div class="profile-img" *ngIf="!profileImage">
            <img [src]="profileImage" alt="" />
        </div>
        <div class="icon">
            <mat-icon *ngIf="!check"><span class="greenicon icon check material-symbols-outlined">
                    check_circle
                </span></mat-icon>
            <mat-icon *ngIf="cross">
                <span class="redIcon check material-symbols-outlined">
                    cancel
                </span>
            </mat-icon>
        </div>

        <div class="content">

            <h5 *ngIf="cross">Sorry, you have already registered with us. </h5>
            <h4 *ngIf="!check">Your Calendar Connected Successfully</h4>
            <p *ngIf="!check">Your calendar has been successfully connected with Papaya Health.You can now manage your
                appointments seemlessly.</p>
            <a href="https://dev.papayahealth.ca/login" class="button">Go To Dashboard</a>
            <p class="pt-2">
                <span style="color: #028b7e; font-weight:500;">Need Help?</span><br>
                Contact Support at
                <strong>admin&#64;papayahealth.ca</strong>
            </p>
        </div>
    </div>
</body> -->

<body>
    <div class="container">
        <div class="logo-container">
            <img alt="Papaya Health" class="logo"
                src="https://img1.wsimg.com/isteam/ip/7c5503d7-591f-48cd-8896-ed3602187251/Logo.png/:/rs=w:481,h:192,cg=true,m/cr=w:481,h:192/qt=q:95" />
        </div>

        <div class="profile-img" *ngIf="profile">
            <img [src]="profile" alt="" />
        </div>

        <div class="icon">
            <mat-icon *ngIf="check">
                <span class="greenicon check material-symbols-outlined">check_circle</span>
            </mat-icon>
            <mat-icon *ngIf="cross">
                <span class="redIcon check material-symbols-outlined">cancel</span>
            </mat-icon>
        </div>

        <div class="content">
            <h5 *ngIf="cross">Sorry, you have been already registered with us.</h5>

            <h4 *ngIf="check"> <span>{{userName}}</span> <br />Your Calendar Connected Successfully</h4>
            <p *ngIf="check">
                Your calendar has been successfully connected with Papaya Health.
                You can now manage your appointments seamlessly.
            </p>
            <span class="button">Visit Papaya App</span>
            <p class="pt-2">
                <span>Need Help?</span><br />
                Contact Support at <strong>admin&#64;papayahealth.ca</strong>
            </p>
        </div>
    </div>
</body>