<h2 mat-dialog-title class="headerColor">{{data.type}}</h2>
<div class="icon">
    <img src="../../../../assets/images/close.png" (click)="close()" class="close-icon" />
</div>
<mat-dialog-content>

    <div>
        <div>
            <div class="example-full-width my-3">
                <label class="mb-2">Heading</label><span class="text-danger">*</span>
                <input class="form-control" type="text" [(ngModel)]="heading" name="heading" maxlength="50" required>
            </div>
            <div class="example-full-width my-3">
                <label class="mb-2">Content </label><span class="text-danger">*</span>
                <textarea class="form-control" [(ngModel)]="content" name="content" maxlength="1000" id="" cols="30"
                    rows="5"></textarea>
                <div class="text-muted" *ngIf="content">{{ 1000 - content.length }}/1000 </div>
            </div>
        </div>
    </div>


</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end mb-2 m-auto">
    <div>
        <button mat-raised-button class="btnText" type="submit" color="primary" (click)="submit()">{{ isEdit ? 'Update' : 'Add'
            }}</button>
        <button mat-raised-button (click)="close()">Close</button>
    </div>

</mat-dialog-actions>