<mat-toolbar>
  <div class="row w-100">
    <div class="col-md-6">
      <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()">
        <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
        <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
      </button>
      <div class="d-flex">
        <img class="avatar" src="../../assets/papaya_logo.svg" />
      </div>
    </div>
    <div class="col-md-6 text-end">
      <span class="userName">{{ this.userName }}</span>
      <button mat-icon-button class="btnImage" [matMenuTriggerFor]="profileMenu">
        <img class="imageSize" [src]="imageUrl " [matMenuTriggerFor]="profileMenu" />
      </button>
      <!-- Profile dropdown -->
      <mat-menu #profileMenu="matMenu">
        <button mat-menu-item (click)="navigateToProfile()">My Profile</button>
        <button mat-menu-item (click)="openResetPasswordDialog()">
          Change Password
        </button>
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" opened>
    <button mat-button class="menu-button" routerLinkActive="active" routerLink="dashboard">
      <div class="d-flex">
        <mat-icon class="mat-elevation-z8">home</mat-icon>
        <span>Dashboard</span>
      </div>
    </button>
    <div>
      <button
        *ngIf="userRole === 'ROLE_SUPER_ADMIN' || (permissions[4]?.moduleType === 'USERS' && permissions[4]?.isView)"
        mat-button class="menu-button" id="sideBarLayout" (click)="toggleSubMenu('User')">
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <mat-icon class="mat-elevation-z8">person</mat-icon>
            <span>User</span>
          </div>
          <div>
            <mat-icon class="arrow-ico"
              [ngClass]="{ rotate: currentSubMenu === 'User' }">keyboard_arrow_right</mat-icon>
          </div>
        </div>
      </button>
    </div>
    <div class="sub-menu" [class.open]="currentSubMenu === 'User'">
      <button mat-button class="menu-button" routerLinkActive="active" routerLink="user/practitioner">
        <span>Practitioner</span>
      </button>
      <button mat-button class="menu-button" routerLink="user/patients" routerLinkActive="active">
        <span>Patient</span>
      </button>

    </div>

    <button
      *ngIf="userRole === 'ROLE_SUPER_ADMIN' || (permissions[3]?.moduleType === 'SUBSCRIPTION' && permissions[3]?.isView)"
      mat-button class="menu-button" [class.active]="isActive('subscription')" routerLink="subscription"
      (click)="toggleSubMenu('Subscription')">
      <div class="d-flex">
        <mat-icon class="mat-elevation-z8">subscriptions</mat-icon>
        <span>Subscription</span>
      </div>
    </button>
    <button
      *ngIf="userRole === 'ROLE_SUPER_ADMIN' || (permissions[1]?.moduleType === 'EXPERTISES' && permissions[1]?.isView)"
      mat-button class="menu-button" routerLinkActive="active" routerLink="expertise"
      (click)="toggleSubMenu('Expertise')">
      <div class="d-flex">
        <mat-icon class="mat-elevation-z8">medical_services</mat-icon>
        <span>Treatments</span>
      </div>
    </button>
    <button
      *ngIf="userRole === 'ROLE_SUPER_ADMIN' || (permissions[5]?.moduleType === 'TECHNIQUES' && permissions[5]?.isView)"
      mat-button class="menu-button" routerLinkActive="active" routerLink="technique"
      (click)="toggleSubMenu('Technique')">
      <div class="d-flex">
        <mat-icon class="mat-elevation-z8">computer</mat-icon>
        <span>Techniques</span>
      </div>
    </button>
    <button
      *ngIf="userRole === 'ROLE_SUPER_ADMIN' || (permissions[0]?.moduleType === 'APPOINTMENTS' && permissions[0]?.isView)"
      mat-button class="menu-button" id="sideBarLayout" routerLinkActive="active" routerLink="all-appointment"
      (click)="toggleSubMenu('Appointment')">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <mat-icon class="mat-elevation-z8">edit</mat-icon>
          <span>Appointments</span>
        </div>
      </div>
    </button>
    <button
      *ngIf="userRole === 'ROLE_SUPER_ADMIN' || (permissions[7]?.moduleType === 'LOCATIONS' && permissions[7]?.isView)"
      mat-button class="menu-button" routerLinkActive="active" routerLink="location"
      (click)="toggleSubMenu('Location')">
      <div class="d-flex">
        <mat-icon class="mat-elevation-z8">location_on</mat-icon>
        <span>Our Location</span>
      </div>
    </button>
    <button
      *ngIf="userRole === 'ROLE_SUPER_ADMIN' || (permissions[9]?.moduleType === 'FEEDBACKS' && permissions[9]?.isView)"
      mat-button class="menu-button" id="sideBarLayout" (click)="toggleSubMenu('Feedback')">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <mat-icon class="mat-elevation-z8">feedback</mat-icon>
          <span>Feedback</span>
        </div>
        <div>
          <mat-icon class="arrow-ico"
            [ngClass]="{ rotate: currentSubMenu === 'Feedback' }">keyboard_arrow_right</mat-icon>
        </div>
      </div>
    </button>
    <div class="sub-menu" [class.open]="currentSubMenu === 'Feedback'">
      <button mat-button class="menu-button" routerLinkActive="active" routerLink="feedback/appFeedback">
        <span>Application Feedback</span>
      </button>
      <button mat-button class="menu-button" routerLink="feedback/feedbackDetail" routerLinkActive="active">
        <span>Practitioner Feedback</span>
      </button>
    </div>
    <button
      *ngIf="userRole === 'ROLE_SUPER_ADMIN' || (permissions[6]?.moduleType === 'SUPPORT' && permissions[6]?.isView)"
      mat-button class="menu-button" routerLinkActive="active" routerLink="support" (click)="toggleSubMenu('Support')">

      <div class="d-flex">
        <mat-icon class="mat-elevation-z8">contact_support</mat-icon>
        <span>Support</span>
      </div>
    </button>
    <button
      *ngIf="userRole === 'ROLE_SUPER_ADMIN' || (permissions[8]?.moduleType === 'SETTINGS' && permissions[8]?.isView)"
      mat-button class="menu-button" routerLinkActive="active" routerLink="global-setting"
      (click)="toggleSubMenu('GlobalSettings')">
      <div class="d-flex">
        <mat-icon class="mat-elevation-z8">settings</mat-icon>
        <span>Global Setting</span>
      </div>
    </button>
    <button *ngIf="userRole !== 'ROLE_ADMIN'" mat-button class="menu-button" routerLinkActive="active"
      routerLink="sub-admin" (click)="toggleSubMenu('Sub-Admin')">
      <div class="d-flex">
        <mat-icon class="mat-elevation-z8">shield_person</mat-icon>
        <span>Sub Admin</span>
      </div>
    </button>
    <button *ngIf="userRole === 'ROLE_SUPER_ADMIN' || (permissions[2]?.moduleType === 'FAQ' && permissions[2]?.isView)"
      mat-button class="menu-button" routerLinkActive="active" routerLink="cms/faq">

      <div class="d-flex">
        <mat-icon class="mat-elevation-z8">description</mat-icon>
        <span>FAQ's</span>
      </div>
    </button>
    <button
      *ngIf="userRole === 'ROLE_SUPER_ADMIN' || (permissions[10]?.moduleType === 'TERMSPOLICIES' && permissions[10]?.isView)"
      mat-button class="menu-button" id="sideBarLayout" (click)="toggleSubMenu('TermsPolicies')">
      <div class="d-flex justify-content-between">
        <div class="d-flex">

          <mat-icon class="mat-elevation-z8">security</mat-icon>
          <span>Terms & Policies</span>
        </div>
        <div>
          <mat-icon class="arrow-ico"
            [ngClass]="{ rotate: currentSubMenu === 'TermsPolicies' }">keyboard_arrow_right</mat-icon>
        </div>
      </div>
    </button>
    <div class="sub-menu" [class.open]="currentSubMenu === 'TermsPolicies'">
      <button mat-button class="menu-button" routerLinkActive="active" routerLink="terms-policies/terms-conditions">
        <span>Terms & Conditions</span>
      </button>
      <button mat-button class="menu-button" routerLinkActive="active" routerLink="terms-policies/privacy-policy">
        <span>Privacy Policy</span>
      </button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="content mat-elevation-z8">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>