import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "../../../shared/services/user.service";

@Component({
	selector: "app-calender-integration",
	templateUrl: "./calender-integration.component.html",
	styleUrl: "./calender-integration.component.css",
})
export class CalenderIntegrationComponent {
	message: any;
	code: any;
	state: any;
	check = false;
	cross = false;
	profile: any;
	userName: any;
	constructor(
		private route: ActivatedRoute,
		private UserService: UserService
	) {}

	ngOnInit(): void {
		this.route.queryParams.subscribe((params: any) => {
			this.code = params["code"];
			this.state = params["state"];
			if (this.code) {
				this.UserService.authorizeCalenderRegistration(
					this.code,
					this.state
				).subscribe(
					(response: any) => {
						console.log(response);
						if (response.body.httpStatus === 200) {
							this.message = response.body.message;
							this.profile = response.body.data.profile;
							this.userName = response.body.data.userName;
							this.check = true;
						} else if (response.httpStatus === 400) {
							this.cross = true;
						}
					},
					(error) => {
						this.message = error.error.message;
						this.cross = true;
					}
				);
			} else {
				this.message = "No code or state provided";
			}
		});
	}
}
