import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
	FormControl,
	Validators,
	FormBuilder,
	FormGroup,
} from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "../../services/login.service";
import { LoaderService } from "../../../shared/services/loader.service";
import { ToasterService } from "../../../shared/services/toaster.service";

@Component({
	selector: "app-otp",
	templateUrl: "./otp.component.html",
	styleUrl: "./otp.component.css",
})
export class OtpComponent implements OnInit {
	otp: FormGroup | any;
	userEmail: string | null | undefined;
	otpInvalid: boolean = false;
	@ViewChild("otpInput") otpInput!: ElementRef;

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private loginService: LoginService,
		private loader: LoaderService,
		private toaster: ToasterService
	) {
		this.otp = this.fb.group({
			otp: ["", [Validators.required]],
		});
		this.userEmail = null;
	}

	ngOnInit(): void {
		this.userEmail = localStorage.getItem("forgetPassEmail");
		this.getOtp();
	}

	getOtp() {
		this.otp = this.fb.group({
			email: this.userEmail,
			otp: [null, [Validators.required]],
			newPassword: null,
			confirmPassword: null,
		});
	}

	onSubmit() {
		if (!this.otp.value.otp) {
			this.otpInvalid = true;
			this.otp.get("otp").markAsTouched();
			this.otpInput.nativeElement.focus();
			return;
		}
		this.loginService.forgotPassword(this.otp.value).subscribe(
			(res: any) => {
				if (res.success === true) {
					this.toaster.success(res.message);
					this.router.navigate(["/reset-password"]);
				}
			},
			(err) => {
				if (err.httpStatus === 400 || (err.error && err.error.message)) {
					this.toaster.error("Invalid  OTP");
					return;
				} else this.toaster.error("Error in getting OTP");
			}
		);
	}
}
