<div class="caontainer-size">
    <div class="row">
        <div class="d-flex align-item-center justify-content-center col-md-6 col-sm-12">
            <img src="../../../../../assets/Papaya.svg" alt="Image" class="img-fluid" />
        </div>
        <div class="col-md-6 contents">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="mb-4">
                        <h3 class="textDecor">Admin Reset Password</h3>
                        <p class="mb-4 textDecor">Enter Your OTP</p>
                    </div>
                    <form [formGroup]="otp" (ngSubmit)="onSubmit()">
                        <div class="field-holder">
                            <input #otpInput type="text" maxLength="6" formControlName="otp" id="otp" required
                                [ngClass]="{'invalid': otpInvalid || (otp.get('otp').invalid && otp.get('otp').touched)}"
                                (focus)="otpInvalid = false" />
                            <label for="otp"> OTP</label>
                            <div *ngIf="otpInvalid || (otp.get('otp').invalid && otp.get('otp').touched)"
                                class="validation-message">
                                Please enter the OTP
                            </div>
                        </div>
                        <div class="form-group mt-5">
                            <button type="submit" class="btn btn-block btn-primary loginbtn w-100">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>