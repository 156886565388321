import { Component, OnInit } from "@angular/core";
import {
	FormBuilder,
	Validators,
	FormGroup,
	AbstractControl,
	ValidationErrors,
} from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "../../services/login.service";
import { ToasterService } from "../../../shared/services/toaster.service";

function passwordMatchValidator(
	control: AbstractControl
): ValidationErrors | null {
	const newPassword = control.get("newPassword");
	const confirmPassword = control.get("confirmPassword");
	return newPassword &&
		confirmPassword &&
		newPassword.value === confirmPassword.value
		? null
		: { passwordMismatch: true };
}

@Component({
	selector: "app-reset-password",
	templateUrl: "./reset-password.component.html",
	styleUrl: "./reset-password.component.css",
})
export class ResetPasswordComponent implements OnInit {
	resetPasswordForm: FormGroup | any;
	userEmail;
	isShow = false;
	isShowconfirm = false;
	constructor(
		private fb: FormBuilder,
		private router: Router,
		private loginService: LoginService,
		private toaster: ToasterService
	) {
		this.userEmail = localStorage.getItem("forgetPassEmail");
	}

	ngOnInit(): void {
		this.resetPassword();
	}

	resetPassword() {
		this.resetPasswordForm = this.fb.group(
			{
				email: this.userEmail,
				otp: null,
				newPassword: ["", [Validators.required]],
				confirmPassword: ["", [Validators.required]],
			},
			{ validator: passwordMatchValidator }
		);
	}

	onSubmit() {
		this.loginService.forgotPassword(this.resetPasswordForm.value).subscribe(
			(res: any) => {
				if (res.success === true) {
					this.toaster.success(res.message);
					this.router.navigate(["/login"]);
				} else if (res.httpStatus === 400) {
					this.toaster.error(res.message);
				}
			},
			(err) => {
				if (err.httpStatus === 400 || err.error) {
					this.toaster.error(err.error.message);
					return;
				} else this.toaster.error("Error in password reset");
			}
		);
	}

	togglePassword() {
		this.isShow = !this.isShow;
	}

	togglePasswordConfirm() {
		this.isShowconfirm = !this.isShowconfirm;
	}
}
