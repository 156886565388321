<div class="caontainer-size">
    <div class="row">
        <div class="d-flex align-item-center justify-content-center col-md-6 col-sm-12">
            <img src="../../../../../assets/Papaya.svg" alt="Image" class="img-fluid">
        </div>
        <div class="col-md-6 contents">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="mb-4">
                        <h3 class="textDecor">Sign In</h3>
                        <p class="mb-4 textDecor">Login to Papaya Admin Panel</p>
                    </div>
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="field-holder">
                            <!-- <label for="email"> Email</label> -->
                            <input type="text" formControlName="email" id="email" placeholder="Email" required>

                        </div>
                        <div *ngIf="loginForm.get('email').invalid && loginForm.get('email').touched"
                            class="text-color">
                            <div *ngIf="loginForm.get('email').errors.required">Email is required.</div>
                            <div *ngIf="loginForm.get('email').errors.email">Please enter valid email.</div>
                        </div>
                        <div class="field-holder">
                            <!-- <label for="Password"> Password</label> -->
                            <input [type]="isShow ? 'text' : 'password'" formControlName="password" id="Password"
                                placeholder="Password" required>

                            <mat-icon class="eye-icon" *ngIf="isShow" (click)="togglePassword()">visibility</mat-icon>
                            <mat-icon class="eye-icon" *ngIf="!isShow"
                                (click)="togglePassword()">visibility_off</mat-icon>
                        </div>
                        <div class="text-color"
                            *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched)">
                            Please provide a password.
                        </div>

                        <div class="col-md-9 mt-2">
                            <a routerLink="/forgot-password" class="forgetPassLink cursor">
                                Forgot Password?
                            </a>
                        </div>
                        <div class="form-group mt-5">
                            <button type="submit" [disabled]="loginForm.invalid"
                                class="btn btn-block btn-primary loginbtn w-100">Login</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>