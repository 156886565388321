import { Component, OnInit } from "@angular/core";
import {
	FormControl,
	Validators,
	FormBuilder,
	FormGroup,
} from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "../../../services/login.service";
import { LoaderService } from "../../../../shared/services/loader.service";
import { ToasterService } from "../../../../shared/services/toaster.service";

@Component({
	selector: "app-forgot-password",
	templateUrl: "./forgot-password.component.html",
	styleUrl: "./forgot-password.component.css",
})
export class ForgotPasswordComponent implements OnInit {
	forgotPass: FormGroup | any;
	otpBox = false;
	constructor(
		private fb: FormBuilder,
		private router: Router,
		private loginService: LoginService,
		private loader: LoaderService,
		private toaster: ToasterService
	) {}

	ngOnInit(): void {
		localStorage.clear();
		this.forgotpassData();
	}

	forgotpassData() {
		this.forgotPass = this.fb.group({
			email: ["", [Validators.required, Validators.email]],
			otp: [null, [Validators.required]],
		});
	}
	onSubmit() {
		if (!this.forgotPass.value.email) {
			this.toaster.warning("Please Enter email");
			return;
		}
		this.loginService.forgotPassword(this.forgotPass.value).subscribe(
			(res: any) => {
				if (res.success === true) {
					localStorage.setItem("forgetPassEmail", this.forgotPass.value.email);
					this.toaster.success(res.message);
					this.router.navigate(["/otp"]);
					return;
				}
				if (res.httpStatus === 417) {
					this.toaster.error(res.message);
				}
			},
			(err) => {
				this.toaster.error(err.message);
				console.log(err);
			}
		);
	}
}
