import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LayoutComponent } from "./layout/layout.component";
import { LoginComponent } from "./authentication/pages/login/login.component";
import { ForgotPasswordComponent } from "./authentication/pages/forgot-password/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./authentication/pages/reset-password/reset-password.component";
import { authGuard } from "./shared/services/auth.guard";
import { OtpComponent } from "./authentication/pages/otp/otp.component";
import { VerifyEmailComponent } from "./authentication/pages/verify-email/verify-email.component";
import { CalenderIntegrationComponent } from "./authentication/pages/calender-integration/calender-integration.component";

const routes: Routes = [
	{
		path: "login",
		component: LoginComponent,
	},
	{ path: "verify-email", component: VerifyEmailComponent },
	{ path: "calendar-integration", component: CalenderIntegrationComponent },
	{
		path: "forgot-password",
		component: ForgotPasswordComponent,
	},
	{
		path: "otp",
		component: OtpComponent,
	},
	{
		path: "reset-password",
		component: ResetPasswordComponent,
	},
	{
		path: "",
		component: LayoutComponent,
		canActivate: [authGuard],
		children: [
			{
				path: "",
				redirectTo: "/dashboard",
				pathMatch: "full",
			},
			{
				path: "dashboard",
				loadChildren: () =>
					import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
			},

			{
				path: "user",
				loadChildren: () =>
					import("./user/user.module").then((m) => m.UserModule),
			},
			{
				path: "subscription",
				loadChildren: () =>
					import("./subscription/subscription/subscription.module").then(
						(m) => m.SubscriptionModule
					),
			},
			{
				path: "expertise",
				loadChildren: () =>
					import("./specialisation/expertise.module").then(
						(m) => m.ExpertiseModule
					),
			},
			{
				path: "technique",
				loadChildren: () =>
					import("./technique/technique.module").then((m) => m.TechniqueModule),
			},
			{
				path: "frontend",
				loadChildren: () =>
					import("./frontend/frontend/frontend.module").then(
						(m) => m.FrontendModule
					),
			},
			{
				path: "cms",
				loadChildren: () => import("./cms/cms.module").then((m) => m.CmsModule),
			},
			{
				path: "all-appointment",
				loadChildren: () =>
					import("./appointment/all-appontment.module").then(
						(m) => m.AllAppontmentModule
					),
			},
			{
				path: "sub-admin",
				loadChildren: () =>
					import("./sub-admin/subadmin.module").then((m) => m.SubadminModule),
			},
			{
				path: "global-setting",
				loadChildren: () =>
					import("./global-setting/global-setting/global-setting.module").then(
						(m) => m.GlobalSettingModule
					),
			},
			{
				path: "support",
				loadChildren: () =>
					import("./support/support/support.module").then(
						(m) => m.SupportModule
					),
			},
			{
				path: "location",
				loadChildren: () =>
					import("./our-location/ourlocation/ourlocation.module").then(
						(m) => m.OurlocationModule
					),
			},
			{
				path: "feedback",
				loadChildren: () =>
					import("./feedback/feedback.module").then((m) => m.FeedbackModule),
			},
			{
				path: "terms-policies",
				loadChildren: () =>
					import("./terms-policies/terms-policies.module").then(
						(m) => m.TermsPoliciesModule
					),
			},
		],
	},
	{
		path: "",
		redirectTo: "/login",
		pathMatch: "full",
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
