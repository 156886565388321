import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { UrlService } from "./url.service";
import { ApiService } from "./api.service";

@Injectable({
	providedIn: "root",
})
export class EmailVerificationService {
	constructor(private apiService: ApiService, private url: UrlService) {}

	verifyEmail(token: any): Observable<any[]> {
		const urlWithQuery = `${this.url.VERIFY_EMAIL}?token=${token}`;
		return this.apiService.get(urlWithQuery);
	}
}
