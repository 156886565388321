// import { Component, OnInit } from "@angular/core";
// import {
// 	FormBuilder,
// 	FormControl,
// 	FormGroup,
// 	Validators,
// } from "@angular/forms";
// import { LoginService } from "../../services/login.service";
// import { Router } from "@angular/router";
// import { LoaderService } from "../../../shared/services/loader.service";
// import { ToasterService } from "../../../shared/services/toaster.service";
// import { UserDetailsService } from "../../../shared/services/user-details.service";

// @Component({
// 	selector: "app-login",
// 	templateUrl: "./login.component.html",
// 	styleUrl: "./login.component.css",
// })
// export class LoginComponent implements OnInit {
// 	loginForm: FormGroup | any;
// 	isShow = false;
// 	constructor(
// 		private fb: FormBuilder,
// 		private loginservice: LoginService,
// 		private router: Router,
// 		private loader: LoaderService,
// 		private toasterService: ToasterService,
// 		private userDetailsService: UserDetailsService
// 	) {}

// 	ngOnInit() {
// 		localStorage.clear();
// 		this.createForm();
// 	}

// 	createForm() {
// 		this.loginForm = this.fb.group({
// 			email: ["", [Validators.required, Validators.email]],
// 			password: ["", [Validators.required, Validators.minLength(6)]],
// 		});
// 	}

// 	onSubmit() {
// 		if (
// 			this.loginForm.value.email == "" ||
// 			this.loginForm.value.password == ""
// 		) {
// 			this.toasterService.warning("Please Fill the Credential");
// 			return;
// 		}
// 		this.loader.showLoader();
// 		this.loginservice.login(this.loginForm.value).subscribe(
// 			(res: any) => {
// 				localStorage.setItem("token", res?.body?.data?.accessToken);
// 				localStorage.setItem("userId", res?.body?.data?.id);
// 				localStorage.setItem("userName", res?.body?.data?.name);
// 				localStorage.setItem("userEmail", res?.body?.data?.email);
// 				localStorage.setItem("userRole", res?.body?.data?.roles[0]?.name);
// 				localStorage.setItem("imageUrl", "../../assets/images/user.png");
// 				localStorage.setItem(
// 					"permissions",
// 					JSON.stringify(res?.body?.data?.permissionDtolist)
// 				);
// 				this.toasterService.success(res?.body?.message);
// 				if (res.body.httpStatus === 400) {
// 					this.toasterService.error(res?.body?.message);
// 					localStorage.clear();
// 					this.loader.hideLoader();
// 					return;
// 				}
// 				if (res.body.data.accessToken) {
// 					this.router.navigate(["/dashboard"]);
// 				}
// 				this.loader.hideLoader();
// 			},
// 			(err) => {
// 				this.loader.hideLoader();
// 				localStorage.clear();
// 				this.toasterService.error("Login failed, please try again.");
// 			}
// 		);
// 	}

// 	togglePassword() {
// 		this.isShow = !this.isShow;
// 	}
// }

import { Component, OnInit } from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { LoginService } from "../../services/login.service";
import { Router } from "@angular/router";
import { LoaderService } from "../../../shared/services/loader.service";
import { ToasterService } from "../../../shared/services/toaster.service";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.css"], // Changed to styleUrls to fix typo
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup | any;
	isShow = false;
	defaultImageUrl: any =
		"https://papaya-health.s3.ca-central-1.amazonaws.com/papaya/USER/images/1715583811878_user.png";
	imageUrl: any = this.defaultImageUrl;
	constructor(
		private fb: FormBuilder,
		private loginservice: LoginService,
		private router: Router,
		private loader: LoaderService,
		private toasterService: ToasterService
	) {}

	ngOnInit() {
		console.log("login");
		localStorage.clear();
		this.createForm();
	}

	createForm() {
		this.loginForm = this.fb.group({
			email: ["", [Validators.required, Validators.email]],
			password: ["", [Validators.required, Validators.minLength(6)]],
		});
	}

	onSubmit() {
		if (
			this.loginForm.value.email == "" ||
			this.loginForm.value.password == ""
		) {
			this.toasterService.warning("Please Fill the Credential");
			return;
		}
		this.loader.showLoader();
		this.loginservice.login(this.loginForm.value).subscribe(
			(res: any) => {
				const userRole = res?.body?.data?.roles[0]?.authority;
				if (userRole === "ROLE_USER") {
					this.toasterService.error("You are not allowed to login.");
					this.loader.hideLoader();
					return;
				}
				if (
					!res?.body?.data?.imageUrl ||
					res?.body?.data?.imageUrl === "N/A" ||
					res?.body?.data?.imageUrl === null
				) {
					this.imageUrl = this.defaultImageUrl;
				} else {
					this.imageUrl = res?.body?.data?.imageUrl;
				}

				localStorage.setItem("token", res?.body?.data?.accessToken);
				localStorage.setItem("userId", res?.body?.data?.id);
				localStorage.setItem("userName", res?.body?.data?.name);
				localStorage.setItem("userEmail", res?.body?.data?.email);
				localStorage.setItem("userRole", userRole);
				localStorage.setItem("imageUrl", this.imageUrl);
				localStorage.setItem(
					"permissions",
					JSON.stringify(res?.body?.data?.permissionDtolist)
				);
				this.toasterService.success(res?.body?.message);

				if (res.body.httpStatus === 400) {
					this.toasterService.error(res?.body?.message);
					localStorage.clear();
					this.loader.hideLoader();
					return;
				}
				if (res.body.data.accessToken) {
					this.router.navigate(["/dashboard"]);
				}
				this.loader.hideLoader();
			},
			(err) => {
				this.loader.hideLoader();
				localStorage.clear();
				this.toasterService.error("Login failed, please try again.");
			}
		);
	}

	togglePassword() {
		this.isShow = !this.isShow;
	}
}
