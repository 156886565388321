<h2 mat-dialog-title class="headerColor">Change Password</h2>
<div class="icon"><img src="../../../../assets/images/close.png" (click)="close()" class="close-icon" /></div>
<mat-dialog-content>
  <p>Email :-{{this.userEmail}}</p>
  <div>
    <form [formGroup]="changePasswordForm">
      <div class="field-holder">
        <input [type]="isShowOldPassword ? 'text' : 'password'" class="form-control" formControlName="oldPassword"
          id="oldPassword" required>
        <label for="oldPassword"> Old Password</label>
        <mat-icon class="eye-icon" *ngIf="isShowOldPassword" (click)="toggleOldPassword()">visibility</mat-icon>
        <mat-icon class="eye-icon" *ngIf="!isShowOldPassword" (click)="toggleOldPassword()">visibility_off</mat-icon>

      </div>
      <div *ngIf="changePasswordForm.get('oldPassword').invalid && changePasswordForm.get('oldPassword').touched"
        class="text-color">
        <div *ngIf="changePasswordForm.get('oldPassword').errors.required">Old Password is required.</div>
      </div>
      <div class="field-holder">

        <input [type]="isShowNewPassword ? 'text' : 'password'" class="form-control" formControlName="newPassword"
          id="newPassword" required>
        <label for="newPassword"> New Password</label>
        <mat-icon class="eye-icon" *ngIf="isShowNewPassword" (click)="toggleNewPassword()">visibility</mat-icon>
        <mat-icon class="eye-icon" *ngIf="!isShowNewPassword" (click)="toggleNewPassword()">visibility_off</mat-icon>

      </div>
      <div *ngIf="changePasswordForm.get('newPassword').invalid && changePasswordForm.get('newPassword').touched"
        class="text-color">
        <div *ngIf="changePasswordForm.get('newPassword').errors.required">New Password is required.</div>
        <div *ngIf="changePasswordForm.get('newPassword').errors.mustNotMatch">New Password must be different from Old
          Password.
        </div>
      </div>
      <div class="field-holder">

        <input [type]="isShowConfirmPassword ? 'text' : 'password'" class="form-control"
          formControlName="confirmPassword" id="confirmPassword" required>
        <label for="confirmPassword">Confirm Password</label>
        <mat-icon class="eye-icon" *ngIf="isShowConfirmPassword" (click)="toggleConfirmPassword()">visibility</mat-icon>
        <mat-icon class="eye-icon" *ngIf="!isShowConfirmPassword"
          (click)="toggleConfirmPassword()">visibility_off</mat-icon>


      </div>
      <div class="text-color"
        *ngIf="changePasswordForm.get('confirmPassword').invalid && (changePasswordForm.get('confirmPassword').dirty || changePasswordForm.get('confirmPassword').touched)">
        Please provide a Confirm Password.
      </div>
      <div class="text-color" *ngIf="changePasswordForm.errors?.mustNotMatch">
        New Password must be different from Old Password.
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end m-4 gap-3">
  <button mat-raised-button (click)="close()">Close</button>
  <button mat-raised-button class="btnText" type="submit" color="primary" (click)="onSubmit()"
    [disabled]="changePasswordForm.invalid">Submit</button>
  <!-- <button mat-button [mat-dialog-close]="data.oldPassword" cdkFocusInitial>Ok</button> -->
</mat-dialog-actions>